import { create } from "zustand";

export const usePageBoard = create((set) => ({
  currentPage: 1,
  updateCurrentPage: (pageNumber) => set(() => ({ currentPage: pageNumber })),
  increaseCurrentPage: () =>
    set((state) => ({
      currentPage: state.currentPage + 1,
    })),
  decreaseCurrentPage: () =>
    set((state) => ({
      currentPage: state.currentPage - 1,
    })),
}));
