import React from "react";
import { MoveLeft, MoveRight } from "lucide-react";
import { usePageBoard } from "../.././components/store/pageBoard";
// @ts-ignore
function PaginationFooter({ arraylength }) {
  const {
    currentPage,
    increaseCurrentPage,
    decreaseCurrentPage,
    updateCurrentPage,
  } = usePageBoard();

  const MAX_VISIBLE_PAGES = 6; // Maximum number of visible page numbers
  const totalPages = Math.ceil(arraylength / 30);

  const handlePrevClick = () => {
    if (currentPage > 1) {
      decreaseCurrentPage();
    }
  };

  const handleNextClick = () => {
    if (currentPage < totalPages) {
      increaseCurrentPage();
    }
  };
  // @ts-ignore
  const onPageChange = (pageNumber) => {
    updateCurrentPage(pageNumber);
  };
  const getVisiblePages = () => {
    const visiblePages = [];
    const startIndex = Math.max(
      currentPage - Math.floor(MAX_VISIBLE_PAGES / 2),
      1
    );
    const endIndex = Math.min(startIndex + MAX_VISIBLE_PAGES - 1, totalPages);

    if (currentPage <= MAX_VISIBLE_PAGES - 1) {
      // Show the first maxVisiblePages pages
      for (let i = 1; i <= endIndex; i++) {
        visiblePages.push(i);
      }
    } else if (currentPage >= totalPages - Math.floor(MAX_VISIBLE_PAGES / 2)) {
      // Show the last maxVisiblePages pages
      for (let i = totalPages - MAX_VISIBLE_PAGES + 1; i <= totalPages; i++) {
        visiblePages.push(i);
      }
    } else {
      // Show the current page, the previous page, the next page, and the surrounding maxVisiblePages - 3 pages
      visiblePages.push(currentPage, currentPage - 1, currentPage + 1);
      for (
        let i = currentPage - Math.floor((MAX_VISIBLE_PAGES - 3) / 2);
        i <= currentPage + Math.floor((MAX_VISIBLE_PAGES - 3) / 2);
        i++
      ) {
        visiblePages.push(i);
      }
    }

    return {
      currentPage,
      totalPages,
    };
  };
  const renderPageNumbers = () => {
    const { currentPage, totalPages } = getVisiblePages();
    const pageNumbers = [];

    if (totalPages <= MAX_VISIBLE_PAGES) {
      // Show all pages if total pages are less than or equal to maxVisiblePages

      for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
      }
    } else {
      if (currentPage <= totalPages - MAX_VISIBLE_PAGES) {
        // Show current page, next page, ellipsis, and last page
        if (currentPage > 1) {
          pageNumbers.push(
            currentPage - 2 > 0 && currentPage - 2,
            currentPage - 1,
            currentPage,
            currentPage + 1,
            currentPage + 2,
            currentPage <= 2 && currentPage + 3,
            "...",
            totalPages
          );
        } else {
          pageNumbers.push(
            currentPage,
            currentPage + 1,
            currentPage + 2,
            currentPage + 3,
            currentPage + 4,
            "...",
            totalPages
          );
        }
      } else {
        // Show last maxVisiblePages pages
        pageNumbers.push("...");
        for (let i = totalPages - MAX_VISIBLE_PAGES + 1; i <= totalPages; i++) {
          pageNumbers.push(i);
        }
      }
    }
    const arrayWithoutBooleans = pageNumbers.filter(
      (element) => typeof element !== "boolean"
    );
    return arrayWithoutBooleans.map((pageNumber) => (
      <div
        key={pageNumber}
        className={pageNumber === currentPage ? " active" : ""}
      >
        {pageNumber === "..." ? (
          <span>...</span>
        ) : (
          <div
            className={`text-gray-500 ${
              pageNumber === currentPage &&
              "bg-lightOrange/20 font-bold text-primary "
            } flex w-3 items-center justify-center rounded-md px-3 py-1 hover:bg-lightOrange/20 hover:text-primary`}
            onClick={() => onPageChange(pageNumber)}
          >
            {pageNumber}
          </div>
        )}
      </div>
    ));
  };

  return (
    <div className="py-2 w-full">
      <div className="flex w-full items-center justify-between space-x-3">
        <button
          disabled={currentPage === 1}
          onClick={handlePrevClick}
          className="fon-medium flex items-center text-sm text-[#667085] disabled:cursor-not-allowed disabled:opacity-50"
        >
          <MoveLeft className="mr-3 h-4 w-4 text-[#667085]" />
          Previous
        </button>
        <div className={`flex cursor-pointer items-center space-x-5 `}>
          {renderPageNumbers()}
        </div>
        <button
          disabled={currentPage === totalPages}
          onClick={handleNextClick}
          className="fon-medium flex items-center text-sm text-[#667085] disabled:cursor-not-allowed disabled:opacity-50"
        >
          Next
          <MoveRight className="ml-2 h-4 w-4 text-[#667085]" />
        </button>
      </div>
    </div>
  );
}

export default PaginationFooter;
